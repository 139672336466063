import { __decorate } from "tslib";
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import Swal from 'sweetalert2';
import { asaasConfiguracaoStore, asaasStore, enderecoStore, financeiroParcelaStore, mainStore, userActionStore } from '@/store';
let FormasPagamentoComponent = class FormasPagamentoComponent extends Vue {
    constructor() {
        super(...arguments);
        this.isLoadingConfirmation = false;
        this.selectedPaymentMethod = null;
        this.valid = false;
        this.pixFromAsaas = null;
        this.pixQrCode = '';
        this.pixCopiaCola = '';
        this.creditCardResponse = null;
        this.financeiroParcela = null;
        this.windowWidth = window.innerWidth;
        this.mobile = false;
        this.isLoadingCep = false;
        this.asaasconfiguracao = {
            receber_cartao_credito: false,
            receber_pix: false,
            pix_taxa_repassada: false,
            cartao_taxa_repassada: false,
            permite_recorrencia_cartao_credito: false,
        };
        this.card = {
            number: '',
            full_name: '',
            expiry: '',
            cvv: '',
            document: '',
            cpf: '',
            cep: '',
            street: '',
            address_number: '',
            complement: ' ',
            neighborhood: '',
            city: '',
            state: '',
            installment: 1
        };
        this.paymentMethods = [
            {
                icon: 'credit_card',
                title: 'Cartão de Crédito',
                description: 'Pague sua mensalidade usando cartão de crédito.',
                value: 'cartao_credito'
            },
            {
                icon: 'qr_code',
                title: 'PIX',
                description: 'Pague sua mensalidade usando PIX. Basta escanear o código QR ou copiar a chave PIX.',
                value: 'pix'
            }
        ];
        this.allowedInstallments = [
            {
                description: '',
                amount: 0
            }
        ];
    }
    async onChangeCep(val) {
        const cepOnlyNumber = val.replace('-', '');
        if (cepOnlyNumber.length !== 8) {
            return;
        }
        const endereco = await enderecoStore.getEndereco(cepOnlyNumber);
        this.card.neighborhood = endereco.bairro;
        this.card.street = endereco.logradouro;
        this.card.cep = cepOnlyNumber;
        this.card.city = endereco.localidade;
        this.card.state = endereco.uf;
    }
    get isKNN() {
        return mainStore.userProfile.company.escola_id === 1;
    }
    get rules() {
        return {
            required: (value) => !!value || 'Este campo é obrigatório',
            requiredLength: (length) => (value) => (value && value.length === length) || `Deve ter no mínimo ${length} dígitos`,
            validDateFormat: (value) => {
                if (!/^\d{2}\/\d{2}$/.test(value)) {
                    return 'Data inválida (use o formato MM/AA)';
                }
                const [monthStr, yearStr] = value.split('/');
                const month = parseInt(monthStr, 10);
                const year = parseInt(yearStr, 10);
                const currentDate = new Date();
                const currentYear = currentDate.getFullYear() % 100;
                const currentMonth = currentDate.getMonth() + 1;
                if (month < 1 || month > 12) {
                    return 'Mês inválido';
                }
                if (year < currentYear || (year === currentYear && month < currentMonth)) {
                    return 'Data de validade expirada';
                }
                return true;
            },
            cpf: (value) => {
                const cpf = value.replace(/\D/g, '');
                if (cpf.length !== 11)
                    return 'CPF inválido';
                if (!/^\d{11}$/.test(cpf))
                    return 'CPF inválido';
                if (/^(\d)\1+$/.test(cpf))
                    return 'CPF inválido';
                const calcCheckDigit = (cpfVal, factor) => {
                    let total = 0;
                    for (let i = 0; i < factor - 1; i++) {
                        total += parseInt(cpfVal[i], 10) * (factor - i);
                    }
                    const remainder = total % 11;
                    return remainder < 2 ? 0 : 11 - remainder;
                };
                if (calcCheckDigit(cpf, 10) !== parseInt(cpf[9], 10))
                    return 'CPF inválido';
                if (calcCheckDigit(cpf, 11) !== parseInt(cpf[10], 10))
                    return 'CPF inválido';
                return true;
            },
            full_name: (value) => /^\p{L}{1,}\s\p{L}{1,}(?:\s\p{L}+)*$/u.test(value) ||
                'Por favor, insira o nome completo'
        };
    }
    get calculatedWidth() {
        if (this.windowWidth > 1000) {
            return '40%';
        }
        if (this.windowWidth > 500) {
            return '60%';
        }
        return '90%';
    }
    get currentUser() {
        return mainStore.userProfile;
    }
    get userActionsPermiteRemoverRecorrenciaEscola() {
        return userActionStore.permiteRemoverRecorrenciaEscola;
    }
    async consultaPagamento() {
        this.financeiroParcela = await financeiroParcelaStore.getFinanceiroParcela(this.financeiroParcelaId);
        this.$forceUpdate();
        if (this.financeiroParcela.status_id == this.getFinanceiroParcelaStatusIdPago()) {
            this.$emit('on-confirm');
        }
    }
    async mounted() {
        window.addEventListener('resize', this.updateWidth);
        this.asaasconfiguracao = await asaasConfiguracaoStore.getAsaasConfiguracaoByFinanceiroParcela({ financeiroParcelaId: this.financeiroParcelaId });
        if (this.asaasconfiguracao && this.asaasconfiguracao.cartao_taxa_repassada) {
            const valorCalculado = this.calculateInstallment(1);
            this.allowedInstallments[0] = {
                description: `1x de ${this.formatPriceValue(valorCalculado.toString())}`,
                amount: valorCalculado
            };
        }
        else {
            this.allowedInstallments[0] = {
                description: `1x de ${this.formatPriceValue(this.payingAmount.toString())}`,
                amount: this.payingAmount
            };
        }
    }
    /** Ciclo de vida (antes de destruir). Equivale ao onUnmounted do Vue 3. */
    beforeDestroy() {
        window.removeEventListener('resize', this.updateWidth);
    }
    /** Atualiza a largura do window. */
    updateWidth() {
        this.windowWidth = window.innerWidth;
    }
    calculateInstallment(installments) {
        let fee = 0.0;
        if (installments === 1) {
            fee = 2.29;
        }
        let finalValue = this.payingAmount * (1 + fee / 100) + 0.29;
        finalValue = Math.round(finalValue * 100) / 100;
        return finalValue;
    }
    /** Copia texto para a área de transferência. */
    async copyToClipboard(content) {
        try {
            await navigator.clipboard.writeText(content);
        }
        catch (error) {
        }
    }
    async handleRemoverCobrancasRecorrente() {
        this.$swal({
            title: 'Confirma a remoção da cobrança recorrente da escola?',
            text: 'Após a remoção, as parcelas em aberto não serão mais debitadas automaticamente.',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Voltar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                this.isLoadingConfirmation = true;
                await asaasStore.asaasCobrancaRemoveRecorrente();
                this.isLoadingConfirmation = false;
            }
        });
    }
    async handlePagamentoAsaasCartaoCredito(permiteCobrancaRecorrenteCartaoCredito = false) {
        this.isLoadingConfirmation = true;
        const payload = {
            holder_name: this.card.full_name,
            number: this.card.number,
            expiry_month: this.card.expiry.slice(0, 2),
            expiry_year: '20' + this.card.expiry.slice(3, 5),
            ccv: this.card.cvv,
            name: this.currentUser?.full_name,
            email: this.currentUser?.email,
            cpf_cnpj: this.card.cpf,
            postal_code: this.card.cep,
            address_number: this.card.address_number,
            address_complement: this.card.complement,
            permite_cobranca_recorrente_cartao_credito: permiteCobrancaRecorrenteCartaoCredito,
        };
        if (permiteCobrancaRecorrenteCartaoCredito) {
            this.$swal({
                title: 'Deseja realmente confirmar a cobrança recorrente?',
                text: 'Após a confirmação todos os seus débitos serão automaticamente quitados na data do vencimento.',
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: 'Voltar',
                confirmButtonText: 'Sim, eu confirmo!',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    this.isLoadingConfirmation = true;
                    this.creditCardResponse = await asaasStore.asaasCobrancaExecuteCartaoCredito({ financeiroParcelaId: this.financeiroParcelaId, payload });
                    if (this.creditCardResponse) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Pagamento concluído com sucesso!',
                            showConfirmButton: true,
                            timer: 4500,
                            timerProgressBar: true
                        });
                    }
                    this.isLoadingConfirmation = false;
                }
            });
        }
        else {
            this.isLoadingConfirmation = true;
            this.creditCardResponse = await asaasStore.asaasCobrancaExecuteCartaoCredito({ financeiroParcelaId: this.financeiroParcelaId, payload });
            if (this.creditCardResponse) {
                Swal.fire({
                    icon: 'success',
                    title: 'Pagamento concluído com sucesso!',
                    showConfirmButton: true,
                    timer: 4500,
                    timerProgressBar: true
                });
            }
        }
        this.isLoadingConfirmation = false;
    }
    async handlePagamentoAsaasPIX() {
        this.isLoadingConfirmation = true;
        this.pixFromAsaas = await asaasStore.asaasCobrancaExecutePIXFranqueadoDrive(this.financeiroParcelaId);
        if (this.pixFromAsaas) {
            this.pixQrCode = this.pixFromAsaas.encodedImage;
            this.pixCopiaCola = this.pixFromAsaas.payload;
        }
        this.isLoadingConfirmation = false;
    }
};
__decorate([
    Prop({ type: Number, default: 0 })
], FormasPagamentoComponent.prototype, "payingAmount", void 0);
__decorate([
    Prop({ type: Number, default: 0 })
], FormasPagamentoComponent.prototype, "financeiroParcelaId", void 0);
__decorate([
    Watch('card.cep', { immediate: true, deep: true })
], FormasPagamentoComponent.prototype, "onChangeCep", null);
FormasPagamentoComponent = __decorate([
    Component
], FormasPagamentoComponent);
export default FormasPagamentoComponent;
